import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { getEventDateFormatted, getYouTubeEmbedUrl, isYoutubeUrl } from "../utils/gatsby-helpers"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import moment from "moment"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"

const EventPage = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const dateFormat = pageContext.dateFormat
    const event = data.event
    const teams = data.teams.edges.map(i => i.node).sort((a, b) => pageContext.teamIds.indexOf(a.id) - pageContext.teamIds.indexOf(b.id))
    const soon = moment().isBefore(event.date_start)

    const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)

    let location = event.location.town ?? ""

    location += (location !== "" && event.location.city != null ? ", " : "") + (event.location.city ?? "")
    location += (location !== "" && event.location.province != null ? ", " : "") + (event.location.province ?? "")
    location += (location !== "" && event.location.country != null ? ", " : "") + (event.location.country ?? "")

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={event.seo.title}
                description={event.seo.meta_description}
                translates={pageContext.translates}
                image={event.seo?.image_2?.localFile?.publicURL} />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg white-section green-lg">
                    <div className="container">
                        <div className="container__50" data-aos="fade-right">
                            <div className="tag tag--highlight">{event.tag}</div>
                            <h1 className="merriweather">{event.title}</h1>
                        </div>
                        <div className="container__50 text--center" data-aos="fade-left">
                            <ul className="ul-list ul-list--lg">
                                <li>
                                    <i className="icon-calendar"></i> {getEventDateFormatted(event, lang, dateFormat, dateFormat)}
                                </li>
                                <li>
                                    <i className="icon-location"></i> {location}
                                </li>
                                {event.location.address && <li>
                                    <i className="icon-pin"></i> {event.location.address}
                                </li>}
                                {event.request_meeting && soon && <LinkButton button={event.request_meeting} className="button button--md" />}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md">
                    <div className="container" data-aos="fade-up">
                        <div className="container__50">
                            {!event.video && <GatsbyImage alt={event.image_inside.alternativeText} image={getImage(event.image_inside.localFile)} />}
                            {event.video && isYoutubeUrl(event.video.video_url) && <div className="video" onClick={() => setIsVideoModalVisible(true)}>
                                <GatsbyImage alt={event.video.video_preview.alternativeText} image={getImage(event.video.video_preview.localFile)} />
                            </div>}
                            {event.video && !isYoutubeUrl(event.video.video_url) && <a href={event.video.video_url} className="video" target="_blank" rel="noreferrer">
                                <GatsbyImage alt={event.video.video_preview.alternativeText} image={getImage(event.video.video_preview.localFile)} />
                            </a>}
                        </div>
                        <div className="container__50">
                            <div>
                                <ReactMarkdown children={event.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                </div>
                {event.our_agenda_items.length > 0 && <div className="main__section main__section--100 color-section gray" >
                    <div className="container">
                        <ReactMarkdown children={event.our_agenda_title} rehypePlugins={[rehypeRaw]} />
                        <div className="grid-lg-3">
                            {event.our_agenda_items.map((item, index) => (
                                <div className="image-group-list" key={index}>
                                    <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} className="img-round" />
                                    <div className="tag tag--highlight tag--highlight--md">{item.tag}</div>
                                    <h5>{item.title}</h5>
                                    <div className="image-group-list__list">
                                        <ul className="ul-list">
                                            {item.date && <li>
                                                <i className="icon-calendar"></i> {item.date}
                                            </li>}
                                            {item.time && <li>
                                                <i className="icon-clock"></i> {item.time}
                                            </li>}
                                            {item.exhibitor && <li>
                                                <i className="icon-user"></i> {`${item.exhibitor.first_name} ${item.exhibitor.last_name}`}
                                            </li>}
                                            {item.other && <li>
                                                <i className={`icon-${item.other.type}`}></i> {`${item.other.text}`}
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
                {event.extra_value && <div className="main__section main__section--50 main__section--50--md">
                    <div className="container">
                        <div className="container__50">
                            <h2>{event.extra_value.title}</h2>
                            <div>
                                <ReactMarkdown
                                    children={event.extra_value.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                            <LinkButton button={event.extra_value.button} className="button button--xl" extras={<i className="icon-arrow-right"></i>} />
                        </div>
                        <div className="container__50">
                            <GatsbyImage image={getImage(event.extra_value.image.localFile)} alt={event.extra_value.image.alternativeText}
                                title={event.extra_value.title}
                                placeholder="blurred"
                                quality={100}
                                className="img-desktop"
                            />
                        </div>
                    </div>
                </div>}
                {teams.length > 0 && <div className="main__section main__section--100">
                    <div className="container">
                        <ReactMarkdown children={event.team_title} rehypePlugins={[rehypeRaw]} />
                        <div className="grid-sm">
                            {teams.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="avatar avatar--vertical" key={index} {...animation}>
                                    <div className="avatar__photo">
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} className="img-round" />
                                    </div>
                                    <div className="avatar__job">
                                        <b>{item.first_name} {item.last_name}</b>
                                        <span>{item.job_position}</span>
                                    </div>
                                    {item.social_networks.length > 0 && <div className="avatar__social-media">
                                        <div className="avatar__social-media__item">
                                            {item.social_networks.map((item, index) => (
                                                <a href={item.url} target="_blank" rel="noreferrer">
                                                    <button className="button-icon button-icon--sm">
                                                        <i className="icon-linkedin-filled"></i>
                                                    </button>
                                                </a>
                                            ))}
                                        </div>
                                    </div>}
                                </div>
                            })}
                        </div>
                    </div>
                </div>}
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <h3>{event.cta_title}</h3>
                            <Link to={event.cta_button.url}>
                                <button className="button button--md">{event.cta_button.text}<i className="icon-arrow-right"></i></button>
                            </Link>
                        </div>
                    </div>
                </div>

                {isVideoModalVisible &&
                    <div className="tc-modal">
                        <div className="tc-modal__content">
                            <div className="tc-modal__content__header">
                                <button className="button-icon button-icon--sm" onClick={() => setIsVideoModalVisible(false)}>
                                    <i className="icon-close"></i>
                                </button>
                            </div>
                            <div className="tc-modal__content__body">
                                <iframe
                                    loading="lazy"
                                    src={getYouTubeEmbedUrl(event.video.video_url)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                            </div>
                        </div>
                    </div>
                }

                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout >
    )
}

export default EventPage

export const eventQuery = graphql`
    query ($id: String!, $langKey: String!, $teamIds: [String]!) {
        event: strapiEvents (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            tag
            title
            description
            date_start
            date_end
            image_preview {
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            quality: 100
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            image_inside {
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            quality: 100
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            location {
                address
                city
            }
            request_meeting {
                text
                url
                blank
            }
            by_tecalis
            our_agenda_title
            our_agenda_items {
                tag
                title
                date
                time
                exhibitor {
                    first_name
                    last_name
                }
                other {
                    type
                    text
                }
                icon {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            team_title
            teams {
                first_name
                last_name
                job_position
                social_networks {
                    type
                    url
                }
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 128
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            extra_value {
                title
                description
                button {
                    text
                    url
                    blank
                }
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            cta_title
            cta_button {
                text
                url
                blank
            }
            video {
                video_url
                video_preview {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
        teams: allStrapiTeams (filter: {locale:{eq: $langKey}, id: {in: $teamIds}}) {
            edges {
                node {
                    id
                    first_name
                    last_name
                    job_position
                    social_networks {
                        type
                        url
                    }
                    image {
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 128
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
